import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { determineTenantName } from "../lib/helpers/tenant";

export const cookieApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "",
    }),
    reducerPath: "cookies",
    endpoints: (build) => ({
        getCsrfCookie: build.query<unknown, void>({
            query: () => "/sanctum/csrf-cookie",
            extraOptions: {},
        }),
        downloadBlob: build.query<Blob | string, string>({
            query: (downloadUrl) => ({
                url: `/${determineTenantName()}${downloadUrl}`,
                responseHandler: (response) =>
                    response.blob().then((blob) => URL.createObjectURL(blob)),
            }),
        }),
    }),
});
export const { useLazyGetCsrfCookieQuery, useLazyDownloadBlobQuery } = cookieApi;
